<template>
    <div id="ReportGalley">
        <v-container fluid app>
            <v-card>
                <v-row no-gutters class="d-flex align-center justify-start mx-2 pt-0">
                    <v-col class="d-flex justify-start ml-2">
                        <div class="d-flex align-start mr-4">
                            <v-icon x-large color="primary" style="margin-top: 6px;">
                                {{ tableIcon }}
                            </v-icon>
                        </div>
                        <div class="d-flex align-center">
                            <span class="text-h6 font-weight-regular">{{ tableName }}</span>
                        </div>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col class="d-flex justify-start">
                        <v-text-field
                            v-model="search"
                            class="mb-6 mr-4"
                            append-icon="mdi-magnify"
                            label="Pesquisar"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-col>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on="{ ...tooltip}"
                                min-width="48px"
                                @click="getSlides()"
                            >
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>Atualizar</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" class="mb-2 mr-4 pa-0"
                                v-on="on"
                                min-width="48px"
                                @click="exportReportsToExcel()"
                                :loading="loadingExport"
                            >
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </template>
                        <span>Exportar Excel</span>
                    </v-tooltip>

                    <v-tooltip top>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn color="primary" class="mb-2 mr-2 pa-0"
                                v-on="{ ...tooltip, ...dialog}"
                                min-width="48px"
                                @click="$router.push('/')"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Fechar</span>
                    </v-tooltip>

                </v-row>

                <v-divider class="mt-1 mb-2"></v-divider>

                <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Tem certeza de que deseja excluir este relatório?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogDelete = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="deleteReport(report)">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="dialogDeleteSlide" max-width="600px">
                    <v-card>
                        <div class="d-flex justify-center">
                            <v-card-title class="headline text-center">Tem certeza de que deseja excluir este slide?</v-card-title>
                        </div>
                        <v-card-actions class="px-6 pb-6">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialogDeleteSlide = false" class="mr-4">Cancelar</v-btn>
                            <v-btn color="primary" @click="deleteSlide(slideId)">Confirmar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-switch v-model="switchOpen" class="ml-2 mb-n14"/>

                <v-data-table
                    :headers="headers"
                    :items="reports"
                    :loading="loading"
                    item-key="id_dynamic_report"
                    sort-by="Nome"
                    class="elevation-1"
                    :expanded.sync="expanded"
                    show-expand
                    ref="dTable"
                >
                    <!-- eslint-disable-next-line -->
                    <template v-slot:item.dynamic_report="{ item }">
                        <v-layout @dblclick="openPreview(item)">
                            {{item.dynamic_report}}
                            <v-spacer></v-spacer>
                            <v-icon
                                class="mr-2"
                                @click="openPreview(item)"
                            >
                                mdi-folder-open
                            </v-icon>
                            <v-icon
                                @click="report = item, dialogDelete = true"
                            >
                                mdi-delete
                            </v-icon>
                        </v-layout>
                    </template>
                    <template v-slot:expanded-item="{ item, headers }">
                        <td :colspan="headers.length">
                            <v-layout wrap v-for="slide in item.slides" :key="slide.id_dynamic_report_slide" >
                                <v-flex xs8 class="ml-12 my-2">
                                    <span class="ma-2"> {{slide.slide_title}} </span>
                                </v-flex>
                                <v-spacer></v-spacer>
                                <v-icon
                                    small
                                    class="mt-2 mr-2"
                                    @click="openSlide(slide)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    small
                                    class="mt-2 mr-2"
                                    @click="slideId = slide.id_dynamic_report_slide, dialogDeleteSlide = true"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-layout>
                        </td>
                    </template>
                </v-data-table>
            </v-card>
            <report-preview
                :dialog="dialog"
                :renderer="''"
                :aggregator="''"
                :vals="[]"
                :headers="[]"
                :filterColsList="[]"
                :events="[]"
                :optionPreview="''"
                :colsFields="[]"
                :rowsFields="[]"
                :slidesList="slides"
                :showTotal="{}"
            >
            </report-preview>
            <hot-grid-report-preview v-if="gridDialog"
                :gridDialog="gridDialog"
                :report="report"
                @closeGridDialog="gridDialog = false"
            >
            </hot-grid-report-preview>
        </v-container>
    </div>
</template>

<script>
import { scaleLinear } from "d3-scale"
import ReportPreview from '../components/znap/ReportPreview'
import HotGridReportPreview from '../components/znap/HotGridReportPreview'

export default {
    name: "ReportGallery",

    components: {
        ReportPreview,
        HotGridReportPreview
    },

    computed: {
        endpoint() {
            return [this.$ipProductionPlanning, "report-gallery"]
        },

        endpointDynamicReport() {
            return [this.$ipDynamicReport, "dynamic-report"]
        },
    },

    data: () => ({
        tableName: "Galeria de relatórios",
        tableIcon: "mdi-table-large",
        tableDescription: "Forneça uma descrição para esta tabela.",
        tableRows: 100000,

        dialog: false,
        dialogDelete: false,
        dialogDeleteSlide: false,

        loading: false,
        loadingExport: false,

        headers: [
            {
                text: 'Nome do relatório', value: 'dynamic_report' 
            }
        ],

        report: {},
        reports: [],
        reportsList: [],
        slideId: null,
        slides: [],

        switchOpen: false,
        expanded: [],
        search: '',

        gridDialog: false,
    }),

    watch: {
        switchOpen (val) {
            val ?
            this.reports.forEach(async (report) => {
                this.expanded.push(report)
            }) :
            this.expanded = []
        },

        //Filtrar por slide, porém buga a busca por slides
        search (val) {
            let filtered = []
            this.reports = this.reportsList
            this.reports.forEach((report) => {
                let founded = false
                report.slides.forEach((slide) => {
                    let title = slide.slide_title.toLowerCase()
                    let dynamic_report = slide.dynamic_report.toLowerCase()
                    title.indexOf(val.toLowerCase()) > -1 || dynamic_report.indexOf(val.toLowerCase()) > -1 ? founded = true : false
                })
                founded ? filtered.push(report) : false
            })
            this.reports = filtered
        }
    },

    created () {
        // this.getReports()
        this.getSlides()
        this.$root.$on('closeDialog', () => {
            this.dialog = false
        })
    },

    methods: {
        colorScaleGenerator(values) {
            const scale = scaleLinear()
                .domain([0, Math.max.apply(null, values)])
                .range(["#fff", "#77f"])
            return (x) => {
                return { "background-color": scale(x) }
            }
        },

        openPreview(report) {
            this.report = report
            this.slides = report.slides
            if (this.slides[0].report_type === 0 || this.slides[0].report_type === '0') {
                this.dialog = true
            } else {
                this.gridDialog = true
            }
        },

        async getReports () {
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 7
                            }
                        ]
                    }
                }

                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report' + "/list",
                    payload
                )
                if (res) {
                    let reportsToFilter = []
                    this.reports = []
                    
                    reportsToFilter = res.data.rows

                    reportsToFilter.forEach((report) => {
                        if (reportsToFilter.findIndex(r => r.id_dynamic_report === report.id_dynamic_report) > -1) {
                            this.reports.push(report)
                        }
                    })
                    // this.reports = res.data.rows
                    this.reports.forEach(async (report) => {
                        report.slides = []
                        // this.expanded.push(report)
                        this.slides.forEach((slide) => {
                            if (report.id_dynamic_report === slide.id_dynamic_report) {
                                report.slides.push(slide)
                            }
                        })
                        // await this.getSlides(report.id_dynamic_report)
                        // report.slides = this.slides
                    })

                    this.reportsList = this.reports
                    this.loading = false
                }
            } catch (err) {
                this.loading = false
                this.$fnError(err)
            }
        },

        async getSlides () {
            this.loading = true
            try {
                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/list"
                )
                if (res) {
                    this.slides = res.data.rows
                    this.slides.forEach((slide) => {
                        if (typeof slide.pivot_params_json === "string") {
                            slide.pivot_params_json = JSON.parse(slide.pivot_params_json)
                        }
                    })
                    this.getReports()
                }
            } catch (err) {
                this.$fnError(err)
            }
        },

        async deleteSlide (slide) {
            try {
                await this.$http.delete(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/delete/" + slide
                )
                this.dialogDeleteSlide = false
                this.getSlides()
            } catch (err) {
                this.dialogDeleteSlide = false
                this.$fnError(err)
            }
        },

        async deleteReport (report) {
            try {
                await this.$http.delete(
                    this.endpointDynamicReport[0] + 'dynamic-report' + "/delete/" + report.id_dynamic_report
                )
                this.dialogDelete = false
                this.getSlides()
            } catch (err) {
                this.dialogDelete = false
                this.$fnError(err)
            }
        },

        async exportReportsToExcel () {
            this.loadingExport = true
            try {
                const payload = {
                    filter: {
                        conditions: [
                            {
                                AndOr: "AND",
                                column: "id_module",
                                operator: "=",
                                value: 7
                            }
                        ]
                    }
                }
                let res = await this.$http.post(
                    this.endpointDynamicReport[0] + 'dynamic-report-slide' + "/report",
                    payload
                )
                if (res) {
                    this.loadingExport = false
                    window.open(res.data.token)
                }
            } catch (err) {
                this.loadingExport = false
                this.$fnError(err)
            }
        },

        openSlide(slide) {
            if (slide.report_type === 0 || slide.report_type === '0') {
                this.$router.push({path: '/detailed-production-planning', query: {report: slide.id_dynamic_report, slide: slide.id_dynamic_report_slide}})
            } else if (slide.report_type === 1 || slide.report_type === '1') {
                this.$router.push({path: '/production-report', query: {report: slide.id_dynamic_report, slide: slide.id_dynamic_report_slide}})
            }
        }
    }
}
</script>
<style scoped>
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}

.v-data-table >>> .v-data-table__wrapper tbody td.text-start{
    background-color: #FAFAFA;
    cursor: pointer;
}

</style>
